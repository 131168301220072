import { useState } from "react";
import { Col, Row } from "antd";
import AssetPath from "../../AssetPath/AssetPath";
import ModalBox from "../../Components/ModalBox/ModalBox";

export default function AnnualConferenceSpeakers() {
  const [open, setOpen] = useState(false);
  const [showData, setShowData] = useState("");

  const handleShowData = (items) => {
    setShowData(items);
    setOpen(true);
  };

  const SpeakersData = [
    {
      name: "Ankit Jain",
      position: "Co-founder",
      companyName: "Infinitus Systems, Inc",
      profileImage: AssetPath.annualConference.ankitJain,
      desc: (
        <>
          <p>
            Ankit Jain is the co-founder and company lead of healthcare AI
            company Infinitus Systems, Inc.
          </p>
          <p>
            Jain co-founded Infinitus after having had two successful exits with
            previous mobile intelligence startup Quettra, where he served as CEO
            (acquired by SimilarWeb), and a search engine named Cuil (acquired
            by Google). In between his operator roles, Jain helped launch and
            run Gradient Ventures, Google’s AI venture capital fund.
          </p>
          <p>
            Ankit’s experience as an operator at private and public companies,
            in addition to his experience as an AI investor, gives him a unique
            perspective on how to build and scale foundational AI technologies.
          </p>
        </>
      ),
    },
    {
      name: "Bala Arunachalam",
      position: "Associate Director - Commercial Insights and Analytics",
      companyName: "Novo Nordisk US",
      profileImage: AssetPath.speakers.balaArunachalam,
      desc: (
        <>
          <p>
            Bala Arunachalam is a seasoned Insights and Analytics (I&A)
            professional with over 15 years of experience in the US pharma,
            having worked with multiple Fortune 500 organizations. He currently
            serves as Associate Director of Insights and Analytics at Novo
            Nordisk US. Bala specializes in data analysis, synthesis,
            integration, and reporting, with a focus on AI/ML applications. His
            commercial expertise spans a range of therapeutic categories,
            including Diabetes, Obesity, CNS, Oncology, and Hemophilia. Bala’s
            passion is to bring innovative analytics solutions to business
            problems, and he excels in guiding teams through challenging
            projects within matrixed organizations. Bala has received numerous
            accolades, including Novo Commercial I&A’s Internal Glory Award for
            fostering innovation and PMSA’s Best Poster Award for a revised NPP
            targeting strategy using ML.
          </p>
          <p>
            Prior to Novo Nordisk, Bala held roles at Bristol Myers Squibb and
            Merck & Co., with I&A projects that significantly impacted strategic
            decisions. Bala brings a background in software engineering with an
            MS in Electrical Engineering from Michigan Technological University
            and an MBA from the University of Michigan.
          </p>
        </>
      ),
    },
    {
      name: "Marady Hill",
      position: "Executive Coach",
      //   companyName: "Novartis",
      profileImage: AssetPath.annualConference.maradyHill,
      desc: (
        <>
          <p>
            <strong>30+ Years of Leadership Experience:</strong> Expert in
            transformation leadership, executive coaching, and cultural
            transformations with global experience across industries like
            technology, biotech, and entertainment.
          </p>
          <p>
            <strong>Certified Coach & Practitioner:</strong> Holds
            certifications including Korn Ferry Enterprise Coach, Certified
            Scrum Master (CSM), and Prosci Change Practitioner.
          </p>
          <p>
            <strong>Specialized Coaching Expertise:</strong> Focus on agile
            transformations, sales leadership, AI-driven change, innovation
            programs, and organizational strategy.
          </p>
          <p>
            <strong>Global & Cross-Cultural Adaptation:</strong> Coached leaders
            worldwide, including expat assignments, with proficiency in Japanese
            and a 98% cross-cultural adaptation rate.
          </p>
          <p>
            <strong>High-Impact Client Results:</strong> Coached C-suite leaders
            in biotech, life sciences, and pharma to improve leadership
            presence, stakeholder engagement, and global strategy.
          </p>
          <p>
            <strong>Creative & Practical Approach:</strong> Combines creative
            experience as a Disney Imagineering producer with query-based
            coaching to elevate leaders' growth and mindset shifts.
          </p>
        </>
      ),
    },
    {
      name: "Prakash Karaka",
      position: "Chief AI Officer",
      companyName: "Chryselys",
      profileImage: AssetPath.speakers.prakashKaraka,
      desc: (
        <>
          <p>
            As Chief AI Officer, Prakash is a guide and a collaborator with
            ethics and governance in mind. His focus is on integrating AI and
            Generative AI technologies, steering Chryselys’ vision towards
            harnessing vast datasets and yielding novel insights & solutions.
            This involves leveraging AI’s predictive capabilities and GenAI’s
            creative potential to revolutionize solutions to address client
            needs.
          </p>
          <p>
            With a deep commitment to leveraging technology for healthcare
            advancement, Prakash brings over 17 years of experience in
            engineering, AI, data analytics, and machine learning to the
            pharmaceutical management science field.
          </p>
          <p>
            Prakash is at the forefront of developing AI solutions that are
            secure, personalized, and transformative for the Pharma industry.
            His roles at Amazon, Microsoft, and Intel have been marked by
            significant contributions in areas ranging from cloud-based big data
            analytics to AI-driven retail and developer services.
          </p>
          <p>
            Prakash earned a Bachelor of Engineering in Electronics and
            Communications from Andhra University, India.
          </p>
        </>
      ),
    },
    {
      name: "Sai Rithvik Kanakamedala",
      position: "Data Scientist",
      companyName: "Novo Nordisk",
      profileImage: AssetPath.annualConference.saiRithvik,
      desc: (
        <>
          <p>
            Sai Rithvik Kanakamedala is a Data Scientist at Novo Nordisk with a
            strong academic background and hands-on experience in advanced
            analytics and machine learning. His work involves development of
            data pipelines and predictive models to enhance HCP targeting
            strategies.
          </p>
          <p>
            Sai has demonstrated expertise in deploying AutoML processes,
            achieving significant efficiency gains, and creating impactful
            machine learning models. With a master's in data science from
            Columbia University and a bachelor's degree from IIT Madras, Sai
            Rithvik has contributed to academic research, publishing work on
            neural network architectures, and has experience in healthcare and
            artificial intelligence research.
          </p>
        </>
      ),
    },
    {
      name: "Suzanne Marzziotti",
      position: "Head, Business Solutions",
      companyName: "Chryselys",
      profileImage: AssetPath.speakers.suzanneMarzziotti,
      desc: (
        <>
          <p>
            With over 30 years of enriching the pharmaceutical and CPG
            industries with actionable insights, Suzanne Marziotti spearheads
            the business solutions vertical at Chryselys. In her role as Head of
            Business Solutions, she strategically applies data to catalyze
            growth and customize solutions that align with the complex needs of
            the pharma industry.
          </p>

          <p>
            Suzanne’s professional journey is marked by a strong emphasis on
            precision medicine reporting and commercial analytics, fostering the
            use of generative AI to enhance predictive modelling across
            therapeutic domains.
          </p>
          <p>
            Her approach weaves together cutting-edge technology and in-depth
            industry knowledge, resulting in robust strategies that elevate both
            client experience and patient care.
          </p>
          <p>
            Suzanne holds a BS in Microbial Genetics from Cornell University,
            complemented by an MBA from NYU’s Stern School of Business, which
            has empowered her to merge scientific inquiry with strategic
            implementation.
          </p>
        </>
      ),
    },
  ];

  return (
    <>
      <section
        className="speakers our-team spacing position-relative bg-light-green"
        data-aos="fade-up"
      >
        <div className="container">
          <div className="d-flex flex-column flex-md-row flex-lg-row align-items-center justify-content-between">
            <div className="text-center w-100">
              <h4 className="title mb-3 text-primary text-center">
                Meet the Speakers
              </h4>
            </div>
          </div>
          <div className="team-list">
            {/* <Carousel {...carouselConfig} ref={carouselRef}> */}
            <Row
              gutter={[
                { xs: 0, sm: 3, md: 3, lg: 3 },
                { xs: 0, sm: 0, md: 0, lg: 0 },
              ]}
            >
              {SpeakersData.map((members, i) => (
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                  lg={{ span: 6 }}
                  key={i}
                >
                  <div
                    className="team-details position-relative cursor-pointer"
                    onClick={() => handleShowData(members)}
                  >
                    <div className="profile-image rounded overflow-hidden">
                      <img
                        src={members.profileImage}
                        alt={members.name}
                        className="w-100 rounded"
                      />
                    </div>
                    <div className="details text-center card">
                      <h4 className="font-4 font-weight-bold mb-1">
                        {members.name}
                      </h4>
                      <p className="text-ellipsis2 mb-0">
                        {members.companyName || members.position}
                      </p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            {/* </Carousel> */}
          </div>
        </div>
      </section>

      <ModalBox
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={null}
        width={900}
      >
        <div className="speaker-profile-informations">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 9 }}
              lg={{ span: 9 }}
            >
              <div className="profile-image rounded overflow-hidden">
                <img
                  src={showData.profileImage}
                  alt={showData.name}
                  className="w-100 rounded"
                />
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 15 }}
              lg={{ span: 15 }}
            >
              <div className="details text-left card p-0">
                <h4 className="text-primary mb-1 border-bottom pb-2 mb-3">
                  <span className=" font-weight-bold">{showData.name}</span>
                  <span className="font-2 fon-weight-normal text-primary d-block">
                    {showData.position}
                    {showData.position && showData.companyName ? ", " : ""}
                    <strong>{showData.companyName}</strong>
                  </span>
                </h4>

                <div className="profile-desc">{showData.desc}</div>
              </div>
            </Col>
          </Row>
        </div>
      </ModalBox>
    </>
  );
}
