import AssetPath from "../../AssetPath/AssetPath";

const HomeSliderData = [
  // {
  //   title: "Innovate Pharma 2024",
  //   secondTitle: "Bridging Continents for a Healthier Tomorrow",
  //   subTitle: "Chennai, India",
  //   dateTime: "September 24-25, 2024 ",
  //   shortDescription: (
  //     <p>
  //       Abstract submission deadline has been extended to September 15, 2024.
  //       Please submit your abstracts to{" "}
  //       <span className="d-block text-primary">
  //         <a href="mailto:sakthi@pharma-sos.com">sakthi@pharma-sos.com</a>.
  //       </span>
  //     </p>
  //   ),
  //   thumbnail: [AssetPath.events.chennaiEvents],
  //   url: "/abstract-submission",
  //   btnText: "Register Now",
  //   className: "bg-light-yellow",
  //   gradient: "bg-light-yellow-gradient",
  // },
  // {
  //   title: "Pharma-SOS Summer Soirée",
  //   subTitle: "1776 by David Burke Topgolf Swing Suite, Morristown, New Jersey",
  //   dateTime: "Thursday, July 25 2024",
  //   shortDescription:
  //     "Enjoy a selection of fine foods, cocktails and inteactive golf games, designed to provide a perfect backdrop for stimulating conversations and new connections",
  //   thumbnail: [AssetPath.events.summerEventMorristown],
  //   url: "/events-and-registration",
  //   btnText: "Know More",
  // },
  {
    title: "Annual Conference 2025",
    subTitle: "Venetian, Las Vegas",
    dateTime: "March 10-11, 2025",
    shortDescription:
      "Experience Pharma-SOS 2025 in Las Vegas for unparalleled insights and networking. Stay tuned for more details!",
    thumbnail: [AssetPath.events.eventVenetianCasinoLasvegas],
    url: "/events-and-registration",
    btnText: "Register Now",
    className: "bg-primary",
    gradient: "bg-light-blue-gradient",
  },
];

export default HomeSliderData;
