import AssetPath from "../../AssetPath/AssetPath";
import InnovatePharmaPath from "../../AssetPath/InnovatePharmaPath";

const GalleryData = [
  {
    innovatePharma: {
      Title: "Innovate Pharma 2024",
      subTitle: "Bridging Continents for a Healthier Tomorrow",
      image: AssetPath.breadcrumb.innovatePhrama2024Gallery,
      path: "innovate-pharma-2024-bridging-continents-for-a-healthier-tomorrow-gallery",
      images: [
        InnovatePharmaPath.Gallery.innovatePharma_1,
        InnovatePharmaPath.Gallery.innovatePharma_10,
        InnovatePharmaPath.Gallery.innovatePharma_100,
        InnovatePharmaPath.Gallery.innovatePharma_101,
        InnovatePharmaPath.Gallery.innovatePharma_102,
        InnovatePharmaPath.Gallery.innovatePharma_105,
        InnovatePharmaPath.Gallery.innovatePharma_103,
        InnovatePharmaPath.Gallery.innovatePharma_104,
        InnovatePharmaPath.Gallery.innovatePharma_106,
        InnovatePharmaPath.Gallery.innovatePharma_107,
        InnovatePharmaPath.Gallery.innovatePharma_108,
        InnovatePharmaPath.Gallery.innovatePharma_109,
        InnovatePharmaPath.Gallery.innovatePharma_11,
        InnovatePharmaPath.Gallery.innovatePharma_110,
        InnovatePharmaPath.Gallery.innovatePharma_111,
        InnovatePharmaPath.Gallery.innovatePharma_112,
        InnovatePharmaPath.Gallery.innovatePharma_113,
        InnovatePharmaPath.Gallery.innovatePharma_114,
        InnovatePharmaPath.Gallery.innovatePharma_115,
        InnovatePharmaPath.Gallery.innovatePharma_116,
        InnovatePharmaPath.Gallery.innovatePharma_117,
        InnovatePharmaPath.Gallery.innovatePharma_118,
        InnovatePharmaPath.Gallery.innovatePharma_119,
        InnovatePharmaPath.Gallery.innovatePharma_12,
        InnovatePharmaPath.Gallery.innovatePharma_120,
        InnovatePharmaPath.Gallery.innovatePharma_121,
        InnovatePharmaPath.Gallery.innovatePharma_122,
        InnovatePharmaPath.Gallery.innovatePharma_123,
        InnovatePharmaPath.Gallery.innovatePharma_124,
        InnovatePharmaPath.Gallery.innovatePharma_125,
        InnovatePharmaPath.Gallery.innovatePharma_126,
        InnovatePharmaPath.Gallery.innovatePharma_127,
        InnovatePharmaPath.Gallery.innovatePharma_128,
        InnovatePharmaPath.Gallery.innovatePharma_129,
        InnovatePharmaPath.Gallery.innovatePharma_13,
        InnovatePharmaPath.Gallery.innovatePharma_130,
        InnovatePharmaPath.Gallery.innovatePharma_131,
        InnovatePharmaPath.Gallery.innovatePharma_132,
        InnovatePharmaPath.Gallery.innovatePharma_133,
        InnovatePharmaPath.Gallery.innovatePharma_134,
        InnovatePharmaPath.Gallery.innovatePharma_135,
        InnovatePharmaPath.Gallery.innovatePharma_136,
        InnovatePharmaPath.Gallery.innovatePharma_137,
        InnovatePharmaPath.Gallery.innovatePharma_138,
        InnovatePharmaPath.Gallery.innovatePharma_139,
        InnovatePharmaPath.Gallery.innovatePharma_14,
        InnovatePharmaPath.Gallery.innovatePharma_140,
        InnovatePharmaPath.Gallery.innovatePharma_141,
        InnovatePharmaPath.Gallery.innovatePharma_142,
        InnovatePharmaPath.Gallery.innovatePharma_143,
        InnovatePharmaPath.Gallery.innovatePharma_144,
        InnovatePharmaPath.Gallery.innovatePharma_15,
        InnovatePharmaPath.Gallery.innovatePharma_16,
        InnovatePharmaPath.Gallery.innovatePharma_17,
        InnovatePharmaPath.Gallery.innovatePharma_18,
        InnovatePharmaPath.Gallery.innovatePharma_19,
        InnovatePharmaPath.Gallery.innovatePharma_2,
        InnovatePharmaPath.Gallery.innovatePharma_20,
        InnovatePharmaPath.Gallery.innovatePharma_21,
        InnovatePharmaPath.Gallery.innovatePharma_22,
        InnovatePharmaPath.Gallery.innovatePharma_23,
        InnovatePharmaPath.Gallery.innovatePharma_24,
        InnovatePharmaPath.Gallery.innovatePharma_25,
        InnovatePharmaPath.Gallery.innovatePharma_26,
        InnovatePharmaPath.Gallery.innovatePharma_27,
        InnovatePharmaPath.Gallery.innovatePharma_28,
        InnovatePharmaPath.Gallery.innovatePharma_29,
        InnovatePharmaPath.Gallery.innovatePharma_3,
        InnovatePharmaPath.Gallery.innovatePharma_30,
        InnovatePharmaPath.Gallery.innovatePharma_31,
        InnovatePharmaPath.Gallery.innovatePharma_32,
        InnovatePharmaPath.Gallery.innovatePharma_33,
        InnovatePharmaPath.Gallery.innovatePharma_34,
        InnovatePharmaPath.Gallery.innovatePharma_35,
        InnovatePharmaPath.Gallery.innovatePharma_36,
        InnovatePharmaPath.Gallery.innovatePharma_37,
        InnovatePharmaPath.Gallery.innovatePharma_38,
        InnovatePharmaPath.Gallery.innovatePharma_39,
        InnovatePharmaPath.Gallery.innovatePharma_4,
        InnovatePharmaPath.Gallery.innovatePharma_40,
        InnovatePharmaPath.Gallery.innovatePharma_41,
        InnovatePharmaPath.Gallery.innovatePharma_42,
        InnovatePharmaPath.Gallery.innovatePharma_43,
        InnovatePharmaPath.Gallery.innovatePharma_44,
        InnovatePharmaPath.Gallery.innovatePharma_45,
        InnovatePharmaPath.Gallery.innovatePharma_46,
        InnovatePharmaPath.Gallery.innovatePharma_47,
        InnovatePharmaPath.Gallery.innovatePharma_48,
        InnovatePharmaPath.Gallery.innovatePharma_49,
        InnovatePharmaPath.Gallery.innovatePharma_5,
        InnovatePharmaPath.Gallery.innovatePharma_50,
        InnovatePharmaPath.Gallery.innovatePharma_51,
        InnovatePharmaPath.Gallery.innovatePharma_52,
        InnovatePharmaPath.Gallery.innovatePharma_53,
        InnovatePharmaPath.Gallery.innovatePharma_54,
        InnovatePharmaPath.Gallery.innovatePharma_55,
        InnovatePharmaPath.Gallery.innovatePharma_56,
        InnovatePharmaPath.Gallery.innovatePharma_57,
        InnovatePharmaPath.Gallery.innovatePharma_58,
        InnovatePharmaPath.Gallery.innovatePharma_59,
        InnovatePharmaPath.Gallery.innovatePharma_6,
        InnovatePharmaPath.Gallery.innovatePharma_60,
        InnovatePharmaPath.Gallery.innovatePharma_61,
        InnovatePharmaPath.Gallery.innovatePharma_62,
        InnovatePharmaPath.Gallery.innovatePharma_63,
        InnovatePharmaPath.Gallery.innovatePharma_64,
        InnovatePharmaPath.Gallery.innovatePharma_65,
        InnovatePharmaPath.Gallery.innovatePharma_66,
        InnovatePharmaPath.Gallery.innovatePharma_67,
        InnovatePharmaPath.Gallery.innovatePharma_68,
        InnovatePharmaPath.Gallery.innovatePharma_69,
        InnovatePharmaPath.Gallery.innovatePharma_7,
        InnovatePharmaPath.Gallery.innovatePharma_70,
        InnovatePharmaPath.Gallery.innovatePharma_71,
        InnovatePharmaPath.Gallery.innovatePharma_72,
        InnovatePharmaPath.Gallery.innovatePharma_73,
        InnovatePharmaPath.Gallery.innovatePharma_74,
        InnovatePharmaPath.Gallery.innovatePharma_75,
        InnovatePharmaPath.Gallery.innovatePharma_76,
        InnovatePharmaPath.Gallery.innovatePharma_77,
        InnovatePharmaPath.Gallery.innovatePharma_78,
        InnovatePharmaPath.Gallery.innovatePharma_79,
        InnovatePharmaPath.Gallery.innovatePharma_8,
        InnovatePharmaPath.Gallery.innovatePharma_80,
        InnovatePharmaPath.Gallery.innovatePharma_81,
        InnovatePharmaPath.Gallery.innovatePharma_82,
        InnovatePharmaPath.Gallery.innovatePharma_83,
        InnovatePharmaPath.Gallery.innovatePharma_84,
        InnovatePharmaPath.Gallery.innovatePharma_85,
        InnovatePharmaPath.Gallery.innovatePharma_86,
        InnovatePharmaPath.Gallery.innovatePharma_87,
        InnovatePharmaPath.Gallery.innovatePharma_88,
        InnovatePharmaPath.Gallery.innovatePharma_89,
        InnovatePharmaPath.Gallery.innovatePharma_9,
        InnovatePharmaPath.Gallery.innovatePharma_90,
        InnovatePharmaPath.Gallery.innovatePharma_91,
        InnovatePharmaPath.Gallery.innovatePharma_92,
        InnovatePharmaPath.Gallery.innovatePharma_93,
        InnovatePharmaPath.Gallery.innovatePharma_94,
        InnovatePharmaPath.Gallery.innovatePharma_95,
        InnovatePharmaPath.Gallery.innovatePharma_96,
        InnovatePharmaPath.Gallery.innovatePharma_97,
        InnovatePharmaPath.Gallery.innovatePharma_98,
        InnovatePharmaPath.Gallery.innovatePharma_99,
      ],
    },
  },
];

export default GalleryData;
