import { Col, Row } from "antd";
import AssetPath from "../../AssetPath/AssetPath";
import PageTitle from "../../Components/PageTitle/PageTitle";
import FAQList from "../FAQ/FAQList";
import CTA from "../LandingPage/CTA";
import { useRef } from "react";
import "./Sponsors.scss";
import SponsorPackages from "./SponsorPackages";
import OurPartners from "../LandingPage/OurPartners";

export default function Sponsors() {
  return (
    <>
      <PageTitle
        mainTitle="Sponsors"
        breadcrumbItems={[{ name: "Sponsors", path: "/sponsors" }]}
        image={AssetPath.breadcrumb.sponsorsImage}
      />

      <SponsorPackages className="spacing-bottom" />

      <OurPartners className="spacing-bottom" />
      <FAQList />

      <CTA
        subTitle="Become a Sponsor at the Annual Conference 2025"
        desc={
          <>
            <p>
              Elevate your brand’s presence in the pharma industry. Join
              industry leaders as a sponsor at{" "}
              <span className="strong">Annual Conference 2025</span>. Showcase
              your brand, connect with key decision-makers, and gain exclusive
              visibility.{" "}
            </p>
            <p>Contact us to explore sponsorship opportunities and benefits.</p>
          </>
        }
        label="Explore Sponsorship "
        image={AssetPath.breadcrumb.subscribeNewsletter}
        noImage
      />
    </>
  );
}
