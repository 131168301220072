import AssetPath from "../../AssetPath/AssetPath";
import Marquee from "react-fast-marquee";

const partners = [
  // {
  //   Title: "Document IT",
  //   image: AssetPath.partners.documentIt,
  //   linkedin: "https://www.linkedin.com/company/document-it-llc/",
  //   website: "https://www.documentit.com/",
  // },
  {
    Title: "ZS",
    image: AssetPath.partners.zs,
    linkedin: "https://www.linkedin.com/company/zs-associates/",
    website: "https://www.zs.com/",
  },
  {
    Title: "Chryselys",
    image: AssetPath.partners.chryselys,
    linkedin: "https://www.linkedin.com/company/chryselys/",
    website: "https://chryselys.com/",
  },
  {
    Title: "Defintive Healthcare",
    image: AssetPath.partners.defintiveHealthcare,
    linkedin:
      "https://www.linkedin.com/company/definitive-healthcare/?originalSubdomain=in",
    website: "https://www.definitivehc.com/",
  },
  {
    Title: "ProcDNA",
    image: AssetPath.partners.procdna,
    linkedin: "https://www.linkedin.com/company/procdna/?originalSubdomain=in",
    website: "https://www.procdna.com/",
  },
  {
    Title: "Purple Lab",
    image: AssetPath.partners.purpleLab,
    linkedin: "https://www.linkedin.com/company/purplelab-inc/",
    website: "https://purplelab.com/",
  },
  {
    Title: "Zoom Rx",
    image: AssetPath.partners.zoomRx,
    linkedin: "https://www.linkedin.com/company/zoomrx/?originalSubdomain=in",
    website: "https://www.zoomrx.com/",
  },
];
export default function OurPartners({ className }) {
  return (
    <section className={`our-partners spacing-top ${className}`}>
      <div className="container">
        <h4 className="text-center mb-2 h3 text-primary" data-aos="fade-up">
          Our Partners
        </h4>
        <div className="d-flex flex-column flex-md-row flex-lg-row align-items-center justify-content-center">
          {/* <p data-aos="fade-up" className="text-primary h4 text-center mb-0">
            Coming Soon
          </p> */}
          <Marquee>
            {partners.map((data, i) => (
              <div className="image">
                <a href={data.website} target="_blank" rel="noreferrer">
                  <img src={data.image} alt={data.Title} title={data.Title} />
                </a>
              </div>
            ))}
          </Marquee>
        </div>
      </div>
    </section>
  );
}
