import React, { useEffect, useState } from "react";
import { Row, Col, Collapse } from "antd";
import { ChevronDown, ChevronUp, Clock } from "feather-icons-react";
import apiClient from "../../Actions/apiClient";
import "./Agenda.scss";

const { Panel } = Collapse;
function callback(key) {
  console.log(key);
}

function sanitizeHTML(content) {
  if (!content) return "";

  try {
    // Use a DOM parser to manipulate HTML content
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");

    // Check if parsing was successful and if doc.body exists
    if (!doc || !doc.body) {
      console.warn(
        "Failed to parse HTML content or content is malformed:",
        content
      );
      return "";
    }

    // Remove empty tags
    doc.querySelectorAll("*").forEach((el) => {
      if (!el.textContent.trim()) {
        el.remove();
      }
    });

    return doc.body.innerHTML || ""; // Ensure innerHTML is a valid string
  } catch (error) {
    console.error(
      "Error while sanitizing HTML content:",
      error,
      "Content:",
      content
    );
    return ""; // Return an empty string if any error occurs
  }
}

export default function AgendaList() {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    apiClient.get("events/list").then(({ data }) => {
      if (data.result) {
        const eventDetails = data.result[0] || [];
        setDataSource(eventDetails);
      }
    });
  }, []);
  console.log("dataSource", dataSource);

  return (
    <>
      <section className="faq agenda spacing" data-aos="fade-up">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 "
            align="center"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
            >
              <div className="highlight-title " data-aos="fade-up">
                {dataSource && (
                  <div className="mb-2">
                    <>
                      <div className="text-center">
                        <h3 className="text-primary text-center mb-0">
                          {dataSource.title}
                        </h3>

                        {dataSource.subtitle ? (
                          <h4 className="text-primary text-center fst-italic mb-1">
                            {dataSource.subtitle}
                          </h4>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="text-center">
                        <h4 className="title mb-2 text-primary">Agenda</h4>
                      </div>
                      <Collapse
                        onChange={callback}
                        expandIconPosition="right"
                        expandIcon={({ isActive }) =>
                          isActive ? (
                            <ChevronUp size={20} />
                          ) : (
                            <ChevronDown size={20} />
                          )
                        }
                      >
                        {dataSource.agendas?.map((agendaList, i) => (
                          <Panel
                            header={
                              <span className="text-uppercase text-primary">
                                <span className=" h4">Day {i + 1} :</span>
                                <span className="ms-3 font-weight-bold">
                                  {agendaList.agendaMainTitle}
                                </span>
                              </span>
                            }
                            key={i}
                            className="custom-panel"
                          >
                            <div className="agenda-list">
                              {agendaList.agendaItems.map((agendas, i) => (
                                <div className="list" key={i}>
                                  <div className="details d-flex flex-column flex-md-row flex-lg-row align-items-left justify-content-between border-top">
                                    <div className="time d-flex align-items-center">
                                      <Clock
                                        size={16}
                                        className="text-primary me-1"
                                      />
                                      <span className="text-gray">
                                        {agendas.menuTime}
                                      </span>
                                    </div>
                                    <div className="notes">
                                      <div className="title-desc">
                                        <h4
                                          className="font-weight-bold text-primary text-uppercase mt-2 mb-1"
                                          style={{ lineHeight: "150%" }}
                                        >
                                          {agendas.menuTitle}
                                        </h4>
                                        {agendas.menuDesc ? (
                                          <div
                                            className="agenda-description d-block text-gray"
                                            dangerouslySetInnerHTML={{
                                              __html: sanitizeHTML(
                                                agendas.menuDesc
                                              ),
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      {agendas.speaker ? (
                                        <div className="speaker-details d-flex align-items-center mt-2">
                                          <div
                                            className="speaker-info d-block text-gray"
                                            dangerouslySetInnerHTML={{
                                              __html: sanitizeHTML(
                                                agendas.speaker
                                              ),
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Panel>
                        ))}
                      </Collapse>
                    </>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
