import React from "react";
import "./FAQ.scss";
import CTA from "../LandingPage/CTA";
import PageTitle from "../../Components/PageTitle/PageTitle";
import AssetPath from "../../AssetPath/AssetPath";
import FAQList from "./FAQList";

export default function FAQ() {
  return (
    <React.Fragment>
      <PageTitle
        mainTitle="Frequently Asked Questions"
        breadcrumbItems={[{ name: "Frequently Asked Questions", path: "/faq" }]}
        image={AssetPath.breadcrumb.agendaImage}
        strokeType="green"
      />
      <FAQList />
      <CTA
        subTitle="Become a Sponsor at the Annual Conference 2025"
        desc={
          <>
            <p>
              Elevate your brand’s presence in the pharma industry. Join
              industry leaders as a sponsor at{" "}
              <span className="strong">Annual Conference 2025</span>. Showcase
              your brand, connect with key decision-makers, and gain exclusive
              visibility.{" "}
            </p>
            <p>Contact us to explore sponsorship opportunities and benefits.</p>
          </>
        }
        label="Explore Sponsorship "
        image={AssetPath.breadcrumb.subscribeNewsletter}
        noImage
      />
    </React.Fragment>
  );
}
