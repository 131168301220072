import { Col, Row } from "antd";
import AssetPath from "../../AssetPath/AssetPath";
import PageTitle from "../../Components/PageTitle/PageTitle";
import CTA from "../LandingPage/CTA";
import { MapPin, ArrowUpRight } from "feather-icons-react";
import { useEffect, useState } from "react";
import apiClient from "../../Actions/apiClient";
import "./EventandRegistration.scss";

export default function EventandRegistration() {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    apiClient.get("events/list").then(({ data }) => {
      if (data && data.result) {
        // Sort events by updatedAt in descending order
        const sortedData = data.result.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        setDataSource(sortedData);
      }
    });
  }, []);

  const renderEventsSection = (title, events) => (
    <section className="spacing bg-white">
      <div className="container">
        <div className="text-center mb-3">
          <h4 className="title text-primary">{title}</h4>
        </div>
        <Row
          gutter={[
            { xs: 10, sm: 16, md: 24, lg: 30 },
            { xs: 10, sm: 16, md: 24, lg: 30 },
          ]}
          justify="center"
          align="middle"
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 18 }}
            lg={{ span: 18 }}
          >
            <div className="upcoming-events">
              {events.map((eventData, index) => (
                <div
                  className="events-list border card p-0 d-flex flex-column flex-md-row flex-lg-row  mt-3"
                  key={index}
                >
                  <div className="image position-relative overflow-hidden">
                    <span
                      style={{ left: "30px" }}
                      className="tags font-4 position-absolute bg-primary text-white bottom-0 font-weight-normal"
                    >
                      {eventData.eventDate}
                      {eventData.eventTime ? ` . ${eventData.eventTime}` : ""}
                    </span>
                    <img
                      src={eventData.thumbnail}
                      alt={eventData.title}
                      className="w-100"
                    />
                  </div>
                  <div className="details text-start">
                    <h3 className="text-primary font-1">{eventData.title}</h3>
                    <h4 className="text-black fst-italic font-2">
                      {eventData.subtitle}
                    </h4>
                    <div className="d-flex">
                      {eventData.priceinDollar && (
                        <div className="tag me-2">
                          $ {eventData.priceinDollar}
                        </div>
                      )}
                      {eventData.priceinRupees && (
                        <div className="tag">₹ {eventData.priceinRupees}</div>
                      )}
                    </div>
                    <p className="text-gray font-4 mb-3 mt-2 text-ellipsis3">
                      {eventData.shortDescription}
                    </p>
                    <div className="border-top text-gray pt-2">
                      <MapPin size={16} className="text-primary me-1" />
                      <span className="font-4">{eventData.location}</span>
                    </div>
                    <div className="buttons-links d-flex align-items-center flex-column flex-md-row flex-lg-row justify-content-start mt-3">
                      {eventData.eventLink1 && (
                        <a
                          href={eventData.eventLink1}
                          className="ant-btn ant-btn-primary"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Register Now
                          <ArrowUpRight size={16} />
                        </a>
                      )}
                      {eventData.eventLink2 && (
                        <a
                          href={eventData.eventLink2}
                          className="ant-btn ant-btn-secondary"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Register Now
                          <ArrowUpRight size={16} className="ms-2" />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );

  // Filter and sort upcoming and past events
  const upcomingEvents = dataSource
    .filter((data) => data.eventType === "UpcomingEvents")
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  return (
    <>
      <PageTitle
        mainTitle="Events and Registration"
        breadcrumbItems={[
          { name: "Events and Registration", path: "/events-and-registration" },
        ]}
        image={AssetPath.breadcrumb.eventandRegistration}
      />

      {upcomingEvents.length > 0 &&
        renderEventsSection("Upcoming Events", upcomingEvents)}
      {/* {pastEvents.length > 0 && renderEventsSection("Past Events", pastEvents)} */}

      <section className="spacing bg-light-green">
        <div className="container">
          <Row
            gutter={[
              { xs: 10, sm: 16, md: 24, lg: 30 },
              { xs: 10, sm: 16, md: 24, lg: 30 },
            ]}
            justify="center"
            align="middle"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
              className="text-center"
            >
              <img
                src={AssetPath.events.eventVenetianCasinoLasvegas}
                alt="Annual Conference 2025 Venetian, Las Vegas"
                className="w-100 rounded"
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
            >
              <h4 className="title text-primary">
                The Venetian Resort Las Vegas
              </h4>
              <p>
                The kinetic energy of The Venetian and the luxuriant maximalism
                of The Palazzo deliver two extravagant experiences under one
                shimmering ceiling. Explore 7,117 lavish suites furnished with
                Italian-inspired comfort and mesmerizing views.
              </p>
              <p>
                <strong className="text-primary">
                  Special Rates Available:
                </strong>{" "}
                Enjoy exclusive rates for Annual Conference 2025 Venetian, Las
                Vegas attendees.
              </p>
              <a
                href="https://book.passkey.com/gt/220396762?gtid=905f48377e135bef3489c3e708d640dc"
                target="_blank"
                rel="noreferrer"
                className="ant-btn ant-btn-secondary"
              >
                Make Your Reservation
                <ArrowUpRight size={16} className="ms-2" />
              </a>
            </Col>
          </Row>
        </div>
      </section>

      <CTA
        title=""
        noImage
        subTitle="Join Our Board"
        label="Apply to Join"
        desc={
          <>
            <p>
              Are you passionate about building a professional community and
              expanding your network in the industry?
            </p>
            <p>
              We invite you to join the Pharma-SOS board and collaborate with
              leaders in the field. Reach out to learn more!
            </p>
          </>
        }
      />
    </>
  );
}
