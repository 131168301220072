import { Col, Row } from "antd";
import AssetPath from "../../AssetPath/AssetPath";
import PageTitle from "../../Components/PageTitle/PageTitle";
import CTA from "../LandingPage/CTA";
import { MapPin, ArrowUpRight } from "feather-icons-react";
import { useEffect, useState } from "react";
import apiClient from "../../Actions/apiClient";
import "./EventandRegistration.scss";
import { useHistory } from "react-router-dom";
import ButtonBox from "../../Components/ButtonBox/ButtonBox";

export default function PastEvents() {
  const [dataSource, setDataSource] = useState([]);

  const history = useHistory();

  useEffect(() => {
    apiClient.get("events/list").then(({ data }) => {
      if (data && data.result) {
        // Sort events by updatedAt in descending order
        const sortedData = data.result.sort(
          (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );
        setDataSource(sortedData);
      }
    });
  }, []);

  const renderEventsSection = (title, events) => (
    <section className="spacing bg-white">
      <div className="container">
        <div className="text-center mb-3">
          <h4 className="title text-primary">{title}</h4>
        </div>
        <Row
          gutter={[
            { xs: 10, sm: 16, md: 24, lg: 30 },
            { xs: 10, sm: 16, md: 24, lg: 30 },
          ]}
          justify="center"
          align="middle"
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 18 }}
            lg={{ span: 18 }}
          >
            <div className="upcoming-events">
              {events.map((eventData, index) => (
                <div
                  className="events-list border card p-0 d-flex flex-column flex-md-row flex-lg-row  mt-3"
                  key={index}
                >
                  <div className="image position-relative overflow-hidden">
                    <span
                      style={{ left: "30px" }}
                      className="tags font-4 position-absolute bg-primary text-white bottom-0 font-weight-normal"
                    >
                      {eventData.eventDate}
                      {eventData.eventTime ? ` . ${eventData.eventTime}` : ""}
                    </span>
                    <img
                      src={eventData.thumbnail}
                      alt={eventData.title}
                      className="w-100"
                    />
                  </div>
                  <div className="details text-start">
                    <h3 className="text-primary font-1">{eventData.title}</h3>
                    <h4 className="text-black fst-italic font-2">
                      {eventData.subtitle}
                    </h4>
                    <div className="d-none">
                      {eventData.priceinDollar && (
                        <div className="tag me-2">
                          $ {eventData.priceinDollar}
                        </div>
                      )}
                      {eventData.priceinRupees && (
                        <div className="tag">₹ {eventData.priceinRupees}</div>
                      )}
                    </div>
                    <p className="text-gray font-4 mb-3 mt-2 text-ellipsis3">
                      {eventData.shortDescription}
                    </p>
                    <div className="border-top text-gray pt-2">
                      <MapPin size={16} className="text-primary me-1" />
                      <span className="font-4">{eventData.location}</span>
                    </div>
                    <div className="buttons-links d-flex align-items-center flex-column flex-md-row flex-lg-row justify-content-start mt-3">
                      {eventData.eventLink1 && (
                        <ButtonBox
                          type="primary"
                          label="More Details"
                          onClick={() => history.push(eventData.pastEventLink)}
                          rightArrowIcon
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );

  const pastEvents = dataSource
    .filter((data) => data.eventType === "PastEvents")
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  return (
    <>
      <PageTitle
        mainTitle="Past Events"
        breadcrumbItems={[
          { name: "Past Events", path: "/events-and-registration" },
        ]}
        image={AssetPath.breadcrumb.pastEvents}
      />

      {pastEvents.length > 0 && renderEventsSection("Past Events", pastEvents)}
    </>
  );
}
